exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-messenger-js": () => import("./../../../src/pages/ai-messenger.js" /* webpackChunkName: "component---src-pages-ai-messenger-js" */),
  "component---src-pages-caya-js": () => import("./../../../src/pages/caya.js" /* webpackChunkName: "component---src-pages-caya-js" */),
  "component---src-pages-collabo-js": () => import("./../../../src/pages/collabo.js" /* webpackChunkName: "component---src-pages-collabo-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hollywood-js": () => import("./../../../src/pages/hollywood.js" /* webpackChunkName: "component---src-pages-hollywood-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indigo-js": () => import("./../../../src/pages/indigo.js" /* webpackChunkName: "component---src-pages-indigo-js" */),
  "component---src-pages-simone-js": () => import("./../../../src/pages/simone.js" /* webpackChunkName: "component---src-pages-simone-js" */),
  "component---src-pages-sound-palette-js": () => import("./../../../src/pages/sound-palette.js" /* webpackChunkName: "component---src-pages-sound-palette-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

